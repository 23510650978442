<template>
  <div>
    <div class="row d-flex justify-content-center">
      <b-card class="card col-md-5 mx-1">
        <b-card-body class="card-body d-flex justify-content-center">
          <img
            :src="require('@/assets/images/logo/golog_b2bpro.png')"
            alt="B2BPro"
            class="logoHeight"
          />
        </b-card-body>
      </b-card>
      <b-card class="card col-md-5 mx-1">
        <b-card-body class="card-body d-flex justify-content-center">
          <img
            :src="require('@/assets/images/logo/golog_smarttruck.png')"
            alt="B2BPro"
            class="logoHeight"
          />
        </b-card-body>
      </b-card>
    </div>
    <div class="row d-flex justify-content-center">
      <b-card class="card col-md-5 mx-1">
        <b-card-body class="card-body">
          <b-row>
            <b-col>
              <feather-icon
                icon="ShoppingCartIcon"
                size="200"
                color="#D3455B"
              />
            </b-col>
            <b-col>
              <h1 class="title1">{{ b2b.activeOrders }}</h1>
              <h1 class="title2">ACTIVE ORDER</h1>
              <h1 class="title3">TODAY</h1>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card class="card col-md-5 mx-1">
        <b-card-body>
          <b-row>
            <b-col>
              <feather-icon icon="TruckIcon" size="200" color="#D3455B" />
            </b-col>
            <b-col>
              <h1 class="title1">{{ b2b.assignedOrdersToday }}</h1>
              <h1 class="title2">ASSIGNED ORDERS</h1>
              <h1 class="title3">TODAY</h1>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card class="card col-md-5 mx-1">
        <b-card-body>
          <b-row>
            <b-col>
              <feather-icon icon="TruckIcon" size="200" color="#D3455B" />
            </b-col>
            <b-col>
              <h1 class="title1">{{ b2b.scheduledOrders }}</h1>
              <h1 class="sched">SCHEDULED ORDERS</h1>
              <h1 class="title3">INCOMING</h1>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card class="card col-md-5 mx-1">
        <b-card-body>
          <b-row>
            <b-col>
              <feather-icon icon="TruckIcon" size="200" color="#D3455B" />
            </b-col>
            <b-col>
              <h1 class="title1">{{ b2b.assignedOrdersNextDay }}</h1>
              <h1 class="sched">ASSIGNED SCHEDULED ORDERS</h1>
              <h1 class="title3">INCOMING</h1>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import { BAlert } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BAlert,
  },
  data() {
    return {
      user: { name: "Name" },
      b2b: [],
    };
  },
  created() {
    this.user = getUserData();
    this.getdata();
  },
  methods: {
    async getdata() {
      const self = this;
      const response = await this.$http.get("/dashboard");
      self.b2b = response.data.data;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/Dashboard.scss";
</style>